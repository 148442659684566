<template>
  <v-row dense>
    <v-col>
      <v-text-field
        v-model="title"
        :rules="titleRules()"
        label="Name"
      ></v-text-field>
      <v-text-field
        v-model="number"
        :rules="numberRules()"
        label="Number"
      ></v-text-field>
      <v-text-field
        v-model="latitude"
        label="Latitude"
        :rules="rules.latitude"
        v-mask="latitudeMask"
      ></v-text-field>
      <v-text-field
        v-model="longitude"
        label="Longitude"
        :rules="rules.longitude"
        v-mask="longitudeMask"
      ></v-text-field>
      <v-text-field
        v-model="phone"
        v-mask="$masks.phone"
        label="Phone"
      ></v-text-field>
      <v-text-field
        v-model="email"
        label="Email"
        :rules="rules.email"
      ></v-text-field>
      <v-checkbox v-model="is_active" label="Active"></v-checkbox>
    </v-col>
    <v-col>
      <v-text-field v-model="address_1" label="Address 1"></v-text-field>
      <v-text-field v-model="address_2" label="Address 2"></v-text-field>
      <v-text-field v-model="city" label="City"></v-text-field>
      <v-select
        v-model="country_id"
        :items="countries"
        item-value="id"
        item-text="country_name"
        label="Country"
        no-data-text="No Countries Available"
      ></v-select>
      <v-select
        v-if="filterById('states', country_id, 'country_id').length"
        v-model="state_id"
        :items="filterById('states', country_id, 'country_id')"
        item-value="id"
        item-text="title"
        label="State/Province"
        no-data-text="No States/Provinces Available"
      ></v-select>
      <v-text-field v-model="postal_code" label="Postal Code"></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import rules from "@/utils/rules";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import hasIn from "lodash.hasin";

const latitudeMask = createNumberMask({
  prefix: "",
  suffix: "",
  allowDecimal: true,
  includeThousandsSeparator: false,
  integerLimit: 2,
  decimalLimit: 8
});
const longitudeMask = createNumberMask({
  prefix: "",
  suffix: "",
  allowDecimal: true,
  includeThousandsSeparator: false,
  integerLimit: 3,
  decimalLimit: 8
});

export default {
  name: "FarmsEditFormFields",
  data() {
    return {
      loading: true,
      rules: {
        required: [rules.required],
        latitude: [rules.maxLength(11)],
        longitude: [rules.maxLength(12)],
        email: [rules.email]
      },
      latitudeMask,
      longitudeMask
    };
  },
  computed: {
    ...mapGetters("baseData", ["countries", "states", "filterById"]),
    ...mapFields("farms", [
      "detailItem.id",
      "detailItem.title",
      "detailItem.number",
      "detailItem.is_active",
      "detailItem.address_1",
      "detailItem.address_2",
      "detailItem.city",
      "detailItem.country_id",
      "detailItem.state_id",
      "detailItem.postal_code",
      "detailItem.phone",
      "detailItem.email",
      "detailItem.latitude",
      "detailItem.longitude"
    ]),
    routeSampleId() {
      return hasIn(this.$route, "params.sampleId")
        ? this.$route.params.sampleId
        : null;
    }
  },
  methods: {
    titleRules() {
      return [
        !!this.title || !!this.number || "Required when no Number is given."
      ];
    },
    numberRules() {
      return [
        !!this.number || !!this.title || "Required when no Name is given."
      ];
    }
  }
};
</script>

<style scoped></style>
